<template>
  <div>
    <vm-table ref="vmTable" v-loading="loading" :post="true" :filter.sync="search"
      url="maintenanceReport/queryElevatorEventReportTemplatePage">
      <template slot="toolbar">
        <el-button class="addBtn" @click="$refs.report.open()">
          {{ $t("elevator.checkMultiReport") }}
        </el-button>
      </template>
      <template slot="adSearch">
        <vm-search :label="$t('common.filter')">
          <el-input v-model.trim="search.templateName" :placeholder="$t('common.pleaseEnter')" clearable></el-input>
        </vm-search>
      </template>
      <el-table-column prop="templateName" :label="$t('elevator.reportName')"></el-table-column>
      <el-table-column prop="elevatorCount" :label="$t('elevator.elevatorCount')"></el-table-column>
      <el-table-column prop="createTime" :label="$t('msgPushRecord.createTime')"></el-table-column>
      <el-table-column :label="$t('common.operate')" align="center" width="150" fixed="right">
        <template slot-scope="scope">
          <el-button type="text" @click="check(scope.row)">{{ $t("contract.check") }}</el-button>
          <el-button type="text" @click="$refs.report.open(scope.row.templateId)">
            {{ $t("common.edit") }}
          </el-button>
          <el-button class="operateDelBtn" type="text" @click="handleDelete(scope.row)">
            {{ $t("common.delete") }}
          </el-button>
        </template>
      </el-table-column>
      <choice-elevator-report ref="report" @save-success="getList(-1)"></choice-elevator-report>
      <choice-date ref="choiceDate"></choice-date>
    </vm-table>
  </div>
</template>
<script>
import ChoiceDate from "@/views/bigDataManger/ChoiceDate";
import ChoiceElevatorReport from "@/views/bigDataManger/ChoiceElevatorReport";

export default {
  components: { ChoiceDate, ChoiceElevatorReport },
  data() {
    return {
      loading: true,
      search: {
        templateName: "",
      },
      elevatorList: [],
    };
  },
  mounted() {
    this.getList(1);
  },
  methods: {
    check(data) {
      const params = {
        templateId: data.templateId,
      };
      this.$http.post("maintenanceReport/queryElevatorEventReportTemplateDetail", params).then(res => {
        this.elevatorList = res.data.elevatorList;
        if (this.elevatorList.length !== 0) {
          const elevatorsIds = this.elevatorList.map(item => item.id);
          // this.$router.resolve 是 Vue Router 提供的一个方法，用于解析路由地址，并返回一个包含路由信息对象
          const route = this.$router.resolve({
            name: "multiElevatorReport",
            params: {
              elevatorsIds: JSON.stringify(elevatorsIds),
              reportName: data.templateName
            },
          });
          // 打开新的窗口
          window.open(route.href, "_blank");
        }
      });
    },
    getData(id) {
      const params = {
        templateId: id,
      };
      this.$http.post("maintenanceReport/queryElevatorEventReportTemplateDetail", params).then(res => {
        this.elevatorList = res.data.elevatorList;
      });
    },
    getList(pageNum) {
      this.loading = false;
      this.$refs.vmTable.getList(pageNum);
    },
    handleDelete(row) {
      const params = {
        templateId: row.templateId,
      };
      this.$http.post("maintenanceReport/delElevatorEventReportTemplate", params).then(res => {
        this.getList(-1);
        this.$message.success(this.$t("common.tip.deleteSuccess"));
      }).catch(() => {
        this.$message.error(this.$t("common.tip.deleteError"));
      });
    },
  },
};
</script>
