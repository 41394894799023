<template>
  <el-tabs v-model="activeName" @tab-click="handleClick">
    <el-tab-pane :label="$t('elevator.singleElevator')" name="first">
      <single-elevator-report-list></single-elevator-report-list>
    </el-tab-pane>
    <el-tab-pane :label="$t('elevator.multiElevator')" name="two">
      <multi-elevator-report-list></multi-elevator-report-list>
    </el-tab-pane>
  </el-tabs>
</template>

<script>
  import SingleElevatorReportList from "@/views/bigDataManger/SingleElevatorReportList";
  import MultiElevatorReportList from "@/views/bigDataManger/MultiElevatorReportList";

  export default {
    components: { SingleElevatorReportList,MultiElevatorReportList },
    data() {
      return {
        activeName: "first",
      };
    },
    methods: {
      handleClick(tab, event) {
        console.log(tab, event);
      },
    },
  };
</script>
<style lang="scss" scoped></style>
