<template>
  <el-dialog
    :close-on-click-modal="false"
    :visible.sync="dialogVisible"
    :title="$t('common.pleaseSelect')"
    width="500px"
    top="3vh"
    append-to-body>
    <el-form
      ref="form"
      :label-width="$l('120px','100px')"
      :model="stateDate">
      <el-form-item :label="$t('elevator.reportStateCycle')">
        <el-date-picker
          v-model="dateRange"
          value-format="yyyy-MM-dd"
          type="daterange"
          :range-separator="$t('common.to')"
          :start-placeholder="$t('common.startDate')"
          :end-placeholder="$t('common.endDate')"
          @change="handleDateChange"
          @clear="clearDateRange">
        </el-date-picker>
      </el-form-item>
    </el-form>
    <span slot="footer">
      <el-button @click="dialogVisible = false">{{$t("elevatorNetApply.cancel")}}</el-button>
      <el-button
        :loading="submitLoading"
        type="primary"
        @click="handleSubmit">{{$t("elevator.createReport")}}</el-button>
    </span>
  </el-dialog>
</template>

<script>
  export default {
    data() {
      return {
        dateRange: [],
        stateDate: {
          startTime: "",
          endTime: "",
          elevatorId: 0,
        },
        dialogVisible: false,
        submitLoading: false,
      };
    },
    methods: {
      open(id) {
        this.elevatorId = id;
        this.dialogVisible = true;
      },
      handleSubmit() {

      },
      handleDateChange(dateRange) {
        if (dateRange != null) {
          this.stateDate.startTime = dateRange[0];
          this.stateDate.endTime = dateRange[1];
        } else {
          this.stateDate.startTime = "";
          this.stateDate.endTime = "";
        }
      },
      clearDateRange() {
        this.dateRange = [];
      },
    },
  };
</script>

<style lang="scss" scoped>

</style>
